.home {

   background-color:#F6F6F6;
   height: 70vh;
   max-height: 520px;
}

.yazi {
   font-size:calc(16px + 1vw);
   margin: 80px 40px 40px 40px;
}

.paper {
   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   border-radius: 6px;
   margin-top: 30px;
}

.MuiListItem-root {
   padding-top: 4px !important;
padding-bottom: 4px !important;
width: auto !important;
}